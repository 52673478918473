const safeGetImage = imgSrc => {
  // prevent throwing and error when an asset can't be found.
  // instead return an empty string in order to show a dead image
  try {
    if (imgSrc && /\w\.\w/.test(imgSrc)) {
      const image = require(`../assets/images/${imgSrc}`)
      return image
    } else {
      return imgSrc
    }
  } catch {
    return ""
  }
}

export default safeGetImage
